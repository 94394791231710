import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f29b954960a2702cc00c6e5a5d52068f@o4507129839616000.ingest.de.sentry.io/4507129842827344",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});